<template>
  <div :class="{'active': isOpen}" class="dropdown">
    <span class="selLabel" @click="switchDropdown">{{ languageLabel }}</span>
    <input name="cd-dropdown" type="hidden">
    <ul class="dropdown-list">
      <li v-for="(lang, i) in languageLabels.filter(l => l.label !== languageLabel)" :key="lang.code" :index="i">
        <span @click="selectLang(lang.label)">{{ lang.label }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "LanguageSelector",
  data: function () {
    return {
      isOpen: false,
      languageLabels: [
        {
          code: 'en',
          label: 'ENG'
        },
        {
          code: 'et',
          label: 'EST'
        },
        {
          code: 'ru',
          label: 'RUS'
        }
      ],
      languageLabel: "ENG"
    }
  },
  props: {
    settings: Object
  },
  methods: {
    switchDropdown: function () {
      this.isOpen = !this.isOpen;
    },
    selectLang: function (label) {
      this.languageLabel = label
      this.isOpen = false;
    }
  }
}
</script>

<style lang="scss">

.dropdown {
  margin-right: 50px;
  width: 30px;
  z-index: 50;
  position: relative;
  perspective: 800px;

  &.active {
    .selLabel {
      &:after {
        content: '\25B2';
      }
    }

    .dropdown-list {
      li {
        &:nth-child(1) {
          transform: translateY(100%);
        }

        &:nth-child(2) {
          transform: translateY(200%);
        }

        &:nth-child(3) {
          transform: translateY(300%);
        }

        &:nth-child(4) {
          transform: translateY(400%);
        }

        &:nth-child(n) {
          background: white;
        }

        :hover {
          color: orange;
        }
      }
    }
  }

  > span {
    /*box-shadow: 0 1px 1px rgba(0,0,0,0.1);*/
    width: 100%;
    height: 14px;
    line-height: 17px;
    color: #515151;
    font-size: 14px;
    background: white;
    margin: 6px 0;
    display: block;
    padding-left: 0.5em;
    position: relative;
    z-index: 9999;
    cursor: pointer;


    transform-style: preserve-3d;
    transform-origin: 0 0;
    transition: transform 300ms;
    -webkit-backface-visibility: hidden;
    -webkit-touch-callout: none;
    user-select: none;

    &:after {
      content: '\25BC';
      position: absolute;
      top: 15%;
      width: 2em;
      text-align: center;
      font-size: 12px;
      left: 2.75em;
      padding: 5px 0px;
      height: 70%;
      line-height: 2.5px;
    }

    &:active {
      transform: rotateX(45deg);

      &:after {
        content: '\25BC';
      }
    }
  }
}

.dropdown-list {
  position: absolute;
  top: 0;
  width: 100%;
  margin: 0;

  li {
    background: #FFF;
    display: block;
    list-style: none;
    left: 0;
    opacity: 1;
    transition: transform 300ms ease;
    padding: 0;
    margin: 0;
    position: absolute;
    top: 0;
    width: 100%;

    &:nth-child(1) {
      z-index: 14;
      transform: translateY(0);
    }

    &:nth-child(2) {
      z-index: 13;
      transform: translateY(0);
    }

    &:nth-child(3) {
      z-index: 12;
      transform: translateY(0);
    }

    &:nth-child(4) {
      z-index: 11;
      transform: translateY(0);
    }

    span {
      -webkit-backface-visibility: hidden;
      -webkit-touch-callout: none;
      user-select: none;

      width: 100%;
      font-size: 14px;
      line-height: 23px;
      padding: 0 0.5em;
      display: block;
      color: #9D9D9D;
      cursor: pointer;
    }
  }
}

.lang-line {
  margin-left: 10%;
  width: 13px;
  float: left;
}
</style>
