<template>
  <div class="mail-message">
    <label class="label" for="email">Palun sisesta oma mailiaadress</label>
    <input id="email" class="input" placeholder="Email" text="" type="text">
    <label class="label" for="content">Maili sisu</label>
    <textarea id="content" class="input" placeholder="Sisu"></textarea>
    <Button class="send-btn" text="Saada" type="small"/>
  </div>
</template>

<script>
import Button from "@/components/Chat/Elements/Button"

export default {
  name: "MailMessage",
  components: {
    Button
  }
}
</script>

<style scoped>
.mail-message {
  display: flex;
  flex-direction: column;
  max-width: 80%;
  align-self: flex-start;
  border: 1px solid #FCA311;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 8px;
}

.label {
  font-size: 14px;
  line-height: 150%;
}

.input {
  padding: 10px;
  background: #FFFFFF;
  box-shadow: inset 0 0 22px rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 10px;
  font-family: 'Monsterrat', sans-serif;
  font-size: 14px;
  line-height: 21px;
  overflow: hidden;
}

.send-btn {
  align-self: flex-end;
}

</style>
