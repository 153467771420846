import Vue from 'vue'
import Vuex from 'vuex'
import App from './App.vue'
import 'core-js/stable'

Vue.config.productionTip = false

Vue.use(Vuex)

const store = new Vuex.Store({
    state: {
        settingsIsOpen: false
    },
    getters: {
        settingsIsOpen: function (state) {
            return state.settingsIsOpen
        }
    },
    mutations: {
        changeSettingsStage: function (state) {
            state.settingsIsOpen = !state.settingsIsOpen
        }
    }
})

new Vue({
    store: store,
    render: h => h(App),
}).$mount('#app')
