<template>
  <a v-if="type === 'large'" class="btn btn-lg-rounded" href="#">
    {{ text }}
  </a>
  <a v-else-if="type === 'link'" class="btn btn-link" href="#">
    <span>{{ text }}</span>
    <span class="icon"><svg class="arrow-right" fill="#2D2D2D"
                            height="15" version="1.1" viewBox="0 0 24 24" width="15"
                            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path
        d="M4,11V13H16L10.5,18.5L11.92,19.92L19.84,12L11.92,4.08L10.5,5.5L16,11H4Z"/></svg></span>
  </a>
  <a v-else-if="type === 'action'" class="btn btn-action" href="#">
    <span>{{ text }}</span>
    <span class="icon"><svg class="launch" fill="#2D2D2D" height="15"
                            version="1.1" viewBox="0 0 24 24" width="15  " xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path
        d="M14,3V5H17.59L7.76,14.83L9.17,16.24L19,6.41V10H21V3M19,19H5V5H12V3H5C3.89,3 3,3.89 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V12H19V19Z"/></svg></span>
  </a>
  <a v-else-if="type === 'small'" class="btn btn-sm-rounded" href="#">
    {{ text }}
  </a>
  <a v-else class="btn btn-sm-transparent">{{ text }}</a>
</template>

<script>

export default {
  name: "Button",
  props: ['type', 'text']
}
</script>

<style lang="scss" scoped>
.btn {
  text-decoration: none;
  text-align: center;
  color: #2D2D2D;
  padding: 8px 16px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 30px;
  background: #FFD692;
  height: 37px;
  font-size: 14px;
  min-height: 35px;
  min-width: 10%;
  max-width: 80%;
  box-sizing: border-box;
  margin: 4px 0;

  &:hover {
    cursor: pointer;
    transition: 0.3s;
  }

  &-lg-rounded {
    height: 50px;
    border-radius: 7px;
    padding: 16px 32px;
    font-size: 18px;

    &:hover {
      background: #FAC773;
      filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
    }
  }

  &-sm {
    &-rounded {
      align-self: flex-start;
      line-height: 21px;

      &:hover {
        background: #FAC773;
        filter: drop-shadow(0px 4px 20px rgba(0, 0, 0, 0.15));
      }
    }

    &-transparent {
      background: transparent;

      &:hover {
        color: #8C8C8C;
      }
    }
  }

  &-link {
    background: #FFD692;
    align-self: flex-start;
    box-sizing: border-box;

    .arrow-right {
      height: 15px;
      vertical-align: middle;
      padding-left: 5px;
    }

    &:hover {
      background: #FCA311;
      color: white;

      .arrow-right {
        fill: white;
        transition: 0.3s;
      }
    }
  }

  &-action {
    background: white;
    align-self: flex-start;
    border: 1px solid #FCA311;
    box-sizing: border-box;

    .launch {
      vertical-align: middle;
      padding-left: 5px;
      height: 15px;
    }

    &:hover {
      background: #FCA311;
      color: white;

      .launch {
        fill: white;
        transition: 0.3s;
      }
    }
  }
}
</style>
