<template>
  <div class="chat-header">
    <div class="chat-header-left">
      <img :src="icon" alt="" class="icon"/>
      <div class="title">Tehisabiline</div>
    </div>
    <div class="chat-header-right">
      <LanguageSelector/>
      <a class="btn" @click="changeSettingsState">
        <svg fill="#2D2D2D" height="24" viewBox="0 0 24 24"
             width="24" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M12,16A2,2 0 0,1 14,18A2,2 0 0,1 12,20A2,2 0 0,1 10,18A2,2 0 0,1 12,16M12,10A2,2 0 0,1 14,12A2,2 0 0,1 12,14A2,2 0 0,1 10,12A2,2 0 0,1 12,10M12,4A2,2 0 0,1 14,6A2,2 0 0,1 12,8A2,2 0 0,1 10,6A2,2 0 0,1 12,4Z"/>
        </svg>
      </a>
      <a class="btn">
        <svg fill="#2D2D2D" height="24" viewBox="0 0 24 24"
             width="24" xmlns="http://www.w3.org/2000/svg">
          <path
              d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
        </svg>
      </a>
    </div>
  </div>
</template>

<script>
import Icon from '@/assets/icon-bot.svg'
import LanguageSelector from "@/components/Chat/Elements/LanguageSelector";

export default {
  name: "ChatHeader",
  components: {
    LanguageSelector
  },
  data: function () {
    return {
      icon: Icon
    }
  },
  methods: {
    changeSettingsState: function () {
      this.$store.commit('changeSettingsStage')
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-header {
  display: flex;
  padding: 8px;
  flex-direction: row;
  justify-content: space-between;

  &-left {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  &-right {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.icon {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.title {
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 27px;
  color: #2D2D2D;
}

.btn {
  text-decoration: none;
  color: #2D2D2D;

  &:hover {
    cursor: pointer;
  }
}
</style>
