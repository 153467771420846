<template>
  <div class="chat-footer">
    <input class="chat-input" placeholder="kirjuta siia..." type="text">
    <a class="btn btn-send" href="#"><img :src="sendBtn" alt=""></a>
  </div>
</template>

<script>
import sendBtn from '@/assets/send-btn.svg'

export default {
  name: "ChatFooter",
  data: function () {
    return {
      sendBtn
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-footer {
  padding: 8px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.chat-input {
  padding: 16px 8px;
  background: #F8F8F8;
  box-shadow: inset 0 0 37px rgba(0, 0, 0, 0.1);
  border: 0;
  border-radius: 10px;
  min-width: 95%;
  box-sizing: border-box;
}

.btn-send {
  position: absolute;
  bottom: 12.5px;
  right: 25px;
  background: 0;
  border: 0;
  text-decoration: none;
  color: #FCA311;
}
</style>
