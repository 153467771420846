<template>
  <div v-if="settingsIsOpen" class="chat-settings">
    <a class="btn-close" href="#" @click="changeSettingsState">
      <svg fill="#2D2D2D" height="24" version="1.1" viewBox="0 0 24 24"
           width="24" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
        <path
            d="M19,6.41L17.59,5L12,10.59L6.41,5L5,6.41L10.59,12L5,17.59L6.41,19L12,13.41L17.59,19L19,17.59L13.41,12L19,6.41Z"/>
      </svg>
    </a>
    <div class="title">Sätted</div>
    <Button text="Vestlusajalugu" type="large"/>
    <Button text="Kustuta ajalugu"></Button>
  </div>
</template>

<script>
import Button from "@/components/Chat/Elements/Button";

export default {
  name: "ChatSettings",
  components: {
    Button
  },
  computed: {
    settingsIsOpen: function () {
      return this.$store.getters.settingsIsOpen
    }
  },
  methods: {
    changeSettingsState: function () {
      this.$store.commit('changeSettingsStage')
    }
  }
}
</script>

<style scoped>
.chat-settings {
  display: flex;
  color: #2D2D2D;
  background: #FFFFFF;
  border-radius: 10px;
  position: absolute;
  z-index: 5;
  flex-direction: column;
  align-items: center;
  bottom: 0;
  right: 0;
  text-align: center;
  width: 100%;
  padding-top: 15px;
}

.title {
  font-size: 25px;
  line-height: 100%;
  margin-bottom: 20px;
}

.btn-close {
  color: #2D2D2D;
  position: absolute;
  top: 10px;
  right: 10px;

  &:hover {
    cursor: pointer;
  }
}
</style>
