<template>
  <div :class="{'user': user}" class="message">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: "Message",
  props: ["text", "user"]
}
</script>

<style scoped>
.message {
  background: #F2F2F2;
  border-radius: 0 15px 15px 15px;
  padding: 8px;
  margin: 4px 0;
  font-size: 14px;
  line-height: 150%;
  float: right;
  align-self: flex-start;
}

.user {
  background: #FFAA2B;
  border-radius: 15px 0 15px 15px;
  align-self: flex-end;
}
</style>
