<template>
  <div class="chat">
    <ChatHeader/>
    <ChatBody/>
    <ChatFooter/>
    <transition name="settings">
      <ChatSettings/>
    </transition>
  </div>
</template>

<script>
import ChatHeader from "@/components/Chat/Header";
import ChatBody from "@/components/Chat/Body";
import ChatFooter from "@/components/Chat/Footer";
import ChatSettings from "@/components/Chat/Settings"

export default {
  name: "Chat",
  components: {
    ChatHeader,
    ChatBody,
    ChatFooter,
    ChatSettings
  }
}
</script>

<style lang="scss" scoped>
.chat {
  background: #FFFFFF;
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  flex-direction: column;
  width: 380px;
  height: 640px;
  border-radius: 15px 15px 0 15px;
  overflow: hidden;
}

.settings-enter-active {
  animation: settings-in .5s;
  transition: .5s;
}

@keyframes settings-in {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (max-width: 750px) {
  .chat {
    position: relative;
    width: 100%;
    height: 100vh;
    bottom: 0;
    right: 0;
    border-radius: 0;
  }
}
</style>
