<template>
  <div :class="{'blurred': settingsIsOpen}" class="chat-body">
    <img :src="iconBot" alt="" class="icon icon-bot"/>
    <Message :user="false" text="Hello!"/>
    <Button text="Mis on Tehisabiline?" type="small"/>
    <Button text="Tasuta demo broneerimine" type="small"/>
    <Button text="Kuidas kontakteeruda?" type="link"/>
    <Button text="Milline on hinnakiri?" type="action"/>
    <img :src="iconUser" alt="" class="icon icon-user"/>
    <Message :user="true" text="Hello!"/>
    <MailMessage/>
    <img :src="lottieAnimation" alt="" class="lottie-anim">
  </div>
</template>

<script>
import iconBot from '@/assets/icon-bot.svg'
import iconUser from '@/assets/icon-user.svg'
import Button from "@/components/Chat/Elements/Button"
import Message from "@/components/Chat/Elements/Message"
import MailMessage from "@/components/Chat/Elements/MailMessage"
import lottieAnimation from "@/assets/lottie.gif"

export default {
  name: "ChatBody",
  components: {
    Button,
    Message,
    MailMessage
  },
  data: function () {
    return {
      iconBot,
      iconUser,
      lottieAnimation
    }
  },
  computed: {
    settingsIsOpen: function () {
      return this.$store.getters.settingsIsOpen
    }
  }
}
</script>

<style lang="scss" scoped>
.chat-body {
  box-sizing: border-box;
  position: relative;
  background: #FFFFFF;
  box-shadow: inset 0 0 25px rgba(0, 0, 0, 0.08);
  min-height: 76%;
  padding: 16px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  overflow-y: scroll;
}

::-webkit-scrollbar {
  width: 5px;

  &-track {
    border-radius: 2px;
  }

  &-thumb {
    background: #FAC773;
    border-radius: 10px;
  }
}

.blurred {
  filter: blur(5px);
  pointer-events: none;
  user-select: none;
  overflow: fragments;
}

.icon {
  height: 20px;

  &-bot {
    align-self: flex-start;
  }

  &-user {
    align-self: flex-end;
  }
}

.lottie-anim {
  transform: translate(-15px);
  width: 50px;
}

@media only screen and (max-width: 750px) {
  .chat-body {
    height: 100%;
  }
}
</style>
